import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Ecommerce = ({ data }) => {

  const { t } = useTranslation();
  if(data===null || typeof data === 'undefined' || Object.keys(data).filter((key)=>key==='url_ecommerce').length === 0){
     return <div></div>;
  }

  var url_ecommerce = data["url_ecommerce"];

  return (
     <Link to={url_ecommerce} target="_blank">
        <div className="big-button-container">
          <button className="big-button-outline" onclick=""> {t('ecommerce')} </button>
        </div>
     </Link>
  );
};

export default Ecommerce;
