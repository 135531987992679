import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import ImageHeader from '../components/ImageHeader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';


import { useConfig } from '../ConfigContext';
import defaultCertificationImage from '../img/default_images/foodchain_certifications.png';


const CertificationDetailPage = ({qr_code_hash, data}) => {

  const config = useConfig();
  const { sha, from } = useParams();
  const { t } = useTranslation();

  const metadata = useMetadata();
  var cert_data = null;


  var certificationName;
  var certificationDescription;
  var certificationUrl;
  var certificationMerchant;
  var certificationImage;

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);

  if(data && data.certification){

       var filtered_certs = data.certification.map((c)=>c.foodchain_certification).filter((c)=> (c.files && c.files.length>0 && c.files[0].document_sha256sum === sha));
       if(filtered_certs.length){
         cert_data = filtered_certs[0];
       }
       else{
           if( data && data.product && data.product.foodchain_product && data.product.foodchain_product.files && data.product.foodchain_product.files.filter((f)=>f.document_sha256sum === sha).length>0){
               var prod_files = data.product.foodchain_product.files.filter((f)=>f.document_sha256sum === sha);
               cert_data = prod_files[0];
           }

           if( data && data.merchant && data.merchant.foodchain_merchant && data.merchant.foodchain_merchant.files && data.merchant.foodchain_merchant.files.filter((f)=>f.document_sha256sum === sha).length>0){
               var merchant_files = data.merchant.foodchain_merchant.files.filter((f)=>f.document_sha256sum === sha);
               cert_data = merchant_files[0];
           }

       }

  }

  if(cert_data){

      certificationName = cert_data.name;
      certificationDescription = cert_data.description;
      certificationUrl = cert_data.files && cert_data.files.length && cert_data.files[0].document ? cert_data.files[0].document : cert_data.document;
      certificationMerchant = cert_data.merchant;
      certificationImage = cert_data.images && cert_data.images.length && cert_data.images[0].image_cropped ? config.DOMAIN+""+cert_data.images[0].image_cropped : defaultCertificationImage;

  }
  else{
    return(
      <PageContainer>
        <div className="page-body">
          <Loader metadata={metadata} />
        </div>
      </PageContainer>
    )
  }


  const linkToCertification = (url) => {
      var link = config.DOMAIN + url;
      window.open(link, '_blank');
  }


  const linkToCertifier = (merch) => {
      var link = config.URL_DOMAIN + merch;
      window.open(link, '_blank');
  }


  return (
    <PageContainer>
       <ImageHeader images={[certificationImage]} back_path={decodeURIComponent(from)} altText="certification" unfixed={true}/>

       <div className="page-body" style={{marginTop:"-60px"}}>

           <h1 className="gtrans">{certificationName}</h1>

           <p className="gtrans">{certificationDescription}</p>

        { certificationUrl &&
          <div className="big-button-container"  onClick={() => linkToCertification(certificationUrl)} >
            <button class="big-button gtrans">{t('leggi_documento')}</button>
          </div>
        }

        { certificationMerchant &&
            <div className="big-button-container"  onClick={() => linkToCertifier(certificationMerchant)} >
              <button class="big-button gtrans">{t('vai_ente_certificatore')}</button>
            </div>
        }



       </div>



    </PageContainer>
  );
};

export default CertificationDetailPage;
