import React, { useState, useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import ImageHeader from '../components/ImageHeader';
import SupplyChainScroller from '../components/SupplyChainScroller';
import FooterNavigator from '../components/FooterNavigator';
import Map from '../components/Map';
import { useTranslation } from 'react-i18next';
import {google_translate_all} from '../tools/utils';
import { useConfig } from '../ConfigContext';
import {downloadPDF} from '../tools/utils';
import { useNavigate } from 'react-router-dom';
import ExtraData from '../components/ExtraData';
import Documents from '../components/Documents';
import ExpandableText from '../components/ExpandableText';
import Loader from '../components/Loader';
import PolicyPopup from '../components/PolicyPopup';
import BadgeScroller from '../components/BadgeScroller';
import MultibatchSelect from '../components/MultibatchSelect';
import Stats from '../components/Stats';
import { useMetadata } from '../contexts/MetadataProvider';
import Certifications from '../components/Certifications';
import Ecommerce from '../components/Ecommerce';

import defaultMerchantImage from '../img/default_images/foodchain_merchant.png';
import defaultProductImage from '../img/default_images/foodchain_products.png';
import batchIcon from '../img/lotto.png';



import env_icon_h2o from '../img/env_h2o.png';
import env_icon_co2 from '../img/env_co2.png';
import env_icon_po4 from '../img/env_po4.png';
import env_icon_km from '../img/env_km.png';


const FirstPage = ({qr_code_hash, productData, merchantData, otherData, multibatchData}) => {

  const [mapPositions, setMapPositions] = useState(null);


  const metadata = useMetadata();



  const { t } = useTranslation();
  const navigate = useNavigate();

  var productName = '';
  var productDescription = '';
  var merchantName = '';
  var productLotNumber = '...';
  var merchantImage = defaultMerchantImage;
  var supplyChainItems = [];
  var id_for_pdf_download = null;
  var item_tx = null;
  var extra_data = null;

  var badge_items = [];






  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);





  /*const concatenateDocuments = (document_list, data, term, nestedTerm) => {
    if (data[term] && data[term].length > 0) {
      data[term].forEach((item) => {
        if (item[nestedTerm].files) {
          document_list = document_list.concat(item[nestedTerm].files);
        }
      });
    }
  };*/


  if(productData){
    productName = productData.name;
    productDescription = productData.description;
    badge_items = productData.claims;


    var stats_items = [];

    if(productData.environmental_co2 && parseFloat(productData.environmental_co2) !== 0){

            stats_items.push(
            {
              icon:env_icon_co2,
              title: 'CO2 '+t('risparmiata'),
              value: productData.environmental_co2+"%",
              color:"green"
            }
          )

    }
    if(productData.environmental_h2o && parseFloat(productData.environmental_h2o) !== 0){
          stats_items.push(
            {
              icon:env_icon_h2o,
              title:'H2O '+t('risparmiata'),
              value: productData.environmental_h2o+" "+t('litri'),
              color:"blue"
            }
          )

    }
    if(productData.environmental_po4 && parseFloat(productData.environmental_po4) !== 0){
          stats_items.push(
            {
              icon:env_icon_po4,
              title:'PO4 '+t('risparmiata'),
              value: productData.environmental_po4+"%",
              color:"yellow"
            }
          )

    }
    if(productData.environmental_km && parseFloat(productData.environmental_km) !== 0){
          stats_items.push(
            {
              icon:env_icon_km,
              title:t('percorso')+" "+t('risparmiato'),
              value: productData.environmental_km+" km",
              color:"purple"
            }
          )

    }



    extra_data = productData.extra;
  }


  const config = useConfig();

  const productImages = productData && productData.images && productData.images.length > 0
    ? productData.images.map(image => config.DOMAIN + image.image_cropped)
    : [defaultProductImage];


  if(merchantData){
    merchantName = merchantData.name;
    if(merchantData.images && merchantData.images.length>0){
      merchantImage = config.DOMAIN+""+merchantData.images[0].image_cropped;
    }
  }

  if(otherData && productData){
    if(otherData.batch){
        var candidates = otherData.batch.filter((b)=>{
          return b.foodchain_batch.parents.includes(productData.item_id);
        });
        if(candidates && candidates.length>0){
          var the_batch = candidates[0];
          if(the_batch){
            productLotNumber = the_batch.foodchain_batch.internal_number;
            extra_data = { ...extra_data, ...the_batch.extra };
          }
        }
    }



    //MAP COORDS
    var the_prov = null;

    //BATCH
    if(!mapPositions && the_batch && the_batch.foodchain_batch && the_batch.foodchain_batch.provenance && the_batch.foodchain_batch.provenance.latitude){
       the_prov = the_batch.foodchain_batch.provenance;
       setMapPositions([[the_prov.latitude, the_prov.longitude, "merchant"]]);
    }

    //PRODUCT
    else if(!mapPositions && productData.provenance && productData.provenance.latitude){
       the_prov = productData.provenance;
       setMapPositions([[the_prov.latitude, the_prov.longitude, "merchant"]]);
    }

    //MERCHANT
    else if(!mapPositions && merchantData && merchantData.provenance && merchantData.address.latitude){
       the_prov = merchantData.address;
       setMapPositions([[the_prov.latitude, the_prov.longitude, "merchant"]]);
    }

    //MERCHANT
    else if(!mapPositions && otherData && otherData.length && otherData[0].foodchain_warehouse && otherData[0].foodchain_warehouse.location){
       the_prov = otherData[0].foodchain_warehouse.location;
       setMapPositions([[the_prov.latitude, the_prov.longitude, "merchant"]]);
    }

  }

  if(otherData && otherData.products && otherData.products.length>0){
    var prods = otherData.products;
    prods.forEach(function(p){
       var item_name = p.foodchain_product.name;
       var item_img = defaultProductImage;
       if(p.foodchain_product.images && p.foodchain_product.images.length>0){
         item_img = config.DOMAIN+""+p.foodchain_product.images[0].img_low_cropped;
       }

       var item_obj = p.foodchain_product;
       item_obj.label = item_name;
       item_obj.imageUrl = item_img;

       //20241011 - only items with mdepth=0 appear here

       if(p.foodchain_product.depth>=0 && p.foodchain_product.mdepth===0 && p.foodchain_product.item_id !== otherData.product.foodchain_product.item_id) supplyChainItems.push(item_obj)
    })


    if(otherData.batch && otherData.batch[0] && otherData.batch[0].foodchain_batch){
      id_for_pdf_download = otherData.batch[0].foodchain_batch.item_id;
    }


    if(otherData.product && otherData.product[0] && otherData.product[0].foodchain_product){
      item_tx = otherData.product[0].foodchain_product.transaction_hash;
    }

  }

  // RETRIEVE ALL DOCUMENTS

  var document_list = [];
  var self_certification_list = [];

  if(otherData){
    console.log(otherData);
    var full_document_list = (otherData.product && otherData.product.foodchain_product) ? otherData.product.foodchain_product.files : [];
    //concatenateDocuments(document_list, otherData, 'batch', 'foodchain_batch');
    document_list = full_document_list.filter((d)=>!d.is_self_certificate);
    console.log("document_list");
    console.log(document_list);
    self_certification_list = full_document_list.filter((d)=>d.is_self_certificate);
  }


  const goToSupplyItem = (index, item) => {
     console.log(index);
     console.log(item);
     navigate("/"+qr_code_hash+'/product/'+item.item_id);
  }



  var supply_chain_title = config.COMPOSITION_LABEL_TYPE;
  if(config.COMPOSITION_LABEL_TYPE==='composition') supply_chain_title = t('composition');


  return (



    <div>

    {
      (multibatchData!==null) &&
      <PageContainer>
        <ImageHeader images={productImages} altText="product" />

        <div className="page-body" style={{marginTop:"min(90vw,450px)"}}>
          <h1>{t('multibatch_title')}</h1>
          <p>
            {t('multibatch_text')}
          </p>

        <MultibatchSelect multibatchData={multibatchData} batchIcon={batchIcon}/>


        </div>
      </PageContainer>
    }


    { (multibatchData==null) && (!productData && !merchantData && !otherData) &&
      <PageContainer>
        <div className="page-body">
         <Loader metadata={metadata}/>
        </div>
      </PageContainer>
    }

    { (multibatchData==null) && (productData || merchantData || otherData) &&
      <PageContainer>

        <ImageHeader images={productImages} altText="product" />
        <div className="page-body" style={{marginTop:"min(460px,100vw)"}}>
          <div className="product-basicinfo">
            <div>
              <img className="product-basicinfo-merchantimage" src={merchantImage} alt="merchant"/>
            </div>
            <div className="product-basicinfo-texts">
              <h1 className="product-basicinfo-text-h1 gtrans">{productName}</h1>
              <div>{merchantName}</div>
              <div>{t('lottonumero')} {productLotNumber}</div>
            </div>
          </div>



          <BadgeScroller items={badge_items} />


          { mapPositions &&
            <div>
                <h2>{t('origine')}</h2>
                <div className="map-outer">
                    <Map mapPositions={mapPositions} merchantIcon={merchantImage} zoomControl={true}/>
                </div>
            </div>
          }

          <Stats items={stats_items} />


          <ExpandableText title={t('descrizione')} text={productDescription} />



          <div>
              <SupplyChainScroller items={supplyChainItems} callback={goToSupplyItem} title={supply_chain_title}/>
          </div>

          <div>
              <Certifications qr_code_hash={qr_code_hash} data={self_certification_list} />
          </div>


          <div>
              <Documents documents={document_list}/>
          </div>



          <div  className="big-button-container" style={{display:"none"}}>
            <div id="button_download_scheda" className="big-button" onClick={ () => downloadPDF(id_for_pdf_download)}>
              <span id="button_download_scheda_label">{t('download_pdf')}</span>
            </div>
          </div>

          {item_tx && <div className="big-button-container">
              <a className="big-button" target="blank" href={ item_tx }>
                <span>{t('view_data')}</span>
              </a>
            </div>
          }


          <ExtraData data={extra_data} />

          <Ecommerce data={extra_data} />

        </div>



        <FooterNavigator qr_code_hash={qr_code_hash} selected_page={1}/>


      </PageContainer>
    }


      <PolicyPopup text={config.POLICY_POPUP_TEXT} />

    </div>
  );
};

export default FirstPage;
