// DateRangeDisplay.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import imageProcess from '../img/processi.png';

const ProcessIndexBox = ({ index }) => {

  const { t } = useTranslation();



  return (
      <div>
      { index &&
          <div className="process-index-box-container">
            <div className="process-index-box-image-outer">
              <img className="process-index-box-image" src={imageProcess} alt="processo"/>
            </div>
            <div className="process-index-box">
              <div>{t('processo')} {parseInt(index,10)+1}</div>
            </div>
          </div>
      }
      </div>
  );
};

export default ProcessIndexBox;
