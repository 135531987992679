import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import Map from '../components/Map';


const MapHeader = ({ mapPositions, merchantIcon }) => {

  mapPositions = mapPositions.filter((mp)=> (typeof mp[0] != 'undefined' && typeof mp[1] != 'undefined'));

  return (
      <div className="header-section" style={{position:"fixed", width:"min(100%,500px)"}}>
        <Map mapPositions={mapPositions} merchantIcon={merchantIcon} zoomControl={false}></Map>
      </div>
  );
};

export default MapHeader;
