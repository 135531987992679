import React from 'react';
import { useConfig } from '../ConfigContext';

const Machinery = ({ machinery }) => {

  const config = useConfig();


  return (
    <div>
      {/* Render the title */}
      <h2>{machinery.name}</h2>

      {/* Render the image if available */}
      {machinery.images && machinery.images.length > 0 && machinery.images[0].img_low_cropped ? (
        <img className="machinery-image" src={config.DOMAIN+machinery.images[0].img_low_cropped} alt={machinery.name} />
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default Machinery;
