// DateRangeDisplay.js
import React from 'react';
import { useTranslation } from 'react-i18next';

import processIcon from '../img/lotto.png';

const ProcessBatches = ({ data, index }) => {

  const { t } = useTranslation();

  var in_lots = null;
  var out_lots = null;



  if(data){
    in_lots = data.in_lots;
    out_lots = data.out_lots;
  }






  return (
    <div className="process-batches-container">

        {  in_lots && in_lots.length>0 && in_lots[0].name &&
        <div className="process-batches-box">
          <div className="process-batches-icon">
            <img src={processIcon} alt="batch"/>
          </div>
          <div className="process-batches-title">{t('lotti_in_entrata')}</div>

            { in_lots.map((l, index) => (
                <div key={index} className="process-batches-section">
                  <div className="process-batches-name">{l.name}</div>
                  <div className="process-batches-number">{t('lottonumero')} {l.lot_number}</div>
                </div>

            ))
            }
        </div>
        }

        { out_lots && out_lots.length>0 && out_lots[0].name &&
        <div className="process-batches-box">
          <div className="process-batches-icon">
            <img src={processIcon} alt="batch"/>
          </div>
          <div className="process-batches-title">{t('lotti_in_uscita')}</div>

            {  out_lots.map((l, index) => (
              <div key={index} className="process-batches-section">
                <div className="process-batches-name">{l.name}</div>
                <div className="process-batches-number">{t('lottonumero')} {l.lot_number}</div>
              </div>

            ))
            }
        </div>
        }

    </div>
  );
};

export default ProcessBatches;
