import React from 'react';
import DocumentItem from './DocumentItem';
import { useConfig } from '../ConfigContext';
import { useTranslation } from 'react-i18next';

import iconUrl from "../img/doc.png";

const Documents = ({ documents }) => {

  const config = useConfig();
  const { t } = useTranslation();

  if (!documents || documents.length === 0) {
    return <div></div>;
  }

  var filtered_documents = documents.filter((d)=>!!d.document);


  // Check if documents array is null or empty
  if (!filtered_documents || filtered_documents.length === 0) {
    return <div></div>;
  }

  return (
    <div className="documents-container">
      <h2>{t('documenti')}</h2>
      {filtered_documents.map((doc, index) => (
        <DocumentItem
          key={index}
          iconUrl={iconUrl}
          label={doc.name}
          link={config.DOMAIN+doc.document}
        />
      ))}
    </div>
  );
};

export default Documents;
