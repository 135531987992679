import React, { useRef } from 'react';
import BadgeItem from './BadgeItem'; // Adjust the import path as needed
import '../styles.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const BadgeScroller = ({ items }) => {

  const { t } = useTranslation();

  const scrollerRef = useRef(null);

  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const onMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollerRef.current.offsetLeft;
    scrollLeft.current = scrollerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scrollerRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // Adjust scrolling speed as needed
    scrollerRef.current.scrollLeft = scrollLeft.current - walk;
  };


  if(items===null || typeof items === 'undefined' || items.length === 0){
     return <div></div>;
  }

  return (

    <div>

        <div
          className="supply-chain-scroller"
          ref={scrollerRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <div className="scroller-inner">
            {items.map((item, the_index) =>
            (
              <BadgeItem
                key={the_index}
                claim={item.claim}
                name={item.name}
                icon={item.icon}
                proof={item.proof}
              />
            )

            )}
          </div>
        </div>

        <div className="badge-scroller-infolink">
          <Link style={{display:'none'}} to="www.automorph.net" >  {t('come_sono_assegnati')} </Link>
        </div>
    </div>

  );
};

export default BadgeScroller;
