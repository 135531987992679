import React from 'react';

const SupplyChainItem = ({ imageUrl, label, onClick, isSelected }) => {


  return (
    <div
      className={`supply-chain-item ${isSelected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <img src={imageUrl} alt={label} className="supply-chain-item-image"/>
      <div className="supply-chain-item-label">{label}</div>
    </div>
  );
};

export default SupplyChainItem;
