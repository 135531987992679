import React from 'react';
import '../styles.css';

const DocumentItem = ({ iconUrl, label, link }) => {
  const handleClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div className="document-item" onClick={handleClick}>
      <div className="document-item-icon-outer">
        <img src={iconUrl} alt={label} className="document-item-icon" />
      </div>
        <span className="document-item-label">{label}</span>
        <span className="document-item-caret">▶</span> {/* Right caret symbol */}
    </div>
  );
};

export default DocumentItem;
