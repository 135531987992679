import React from 'react';
import { format_label, truncate_string } from "../tools/utils.js";

const ExtraDataItem = ({ label, value }) => {
  const formatted_label = format_label(label);

  // Check if the value starts with 'http' and render it as a link
  const formatted_value = value.startsWith('http') ? (
    <a className="extra-data-link" href={value} target="_blank" rel="noopener noreferrer">
      {truncate_string(value,30)}
    </a>
  ) : (
    truncate_string(value,30)
  );

  return (
    <div className="extra-data-item">
      <span className="label">{formatted_label}</span>
      <span className="value">{formatted_value}</span>
    </div>
  );
};

export default ExtraDataItem;
