// MapComponent.js
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';

// Import the custom marker image
import customMarkerImg from '../img/pin_2_white.png'; // Update the path to your image file
import customMarkerImg2 from '../img/pin_warehouse.png'; // Update the path to your image file

const Map = ({ mapPositions, merchantIcon, zoomControl }) => {

    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        if (mapPositions && mapPositions.length > 0) {
            setShowMap(true);
        }
    }, [mapPositions]);


    // Create a custom icon
    const customIconMerchant = L.icon({
        iconUrl: customMarkerImg,   // Path to your custom marker image
        shadowUrl: merchantIcon,   // Path to your custom marker image
        iconSize: [50, 63],         // Size of the icon
        shadowSize: [36, 34],         // Size of the icon
        shadowAnchor: [17, 36],         // Size of the icon
        iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
        popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
    });
    // Create a custom icon
    const customIconWarehouse = L.icon({
        iconUrl: customMarkerImg2,   // Path to your custom marker image
        iconSize: [48, 64],         // Size of the icon
        iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
        popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
    });

    return (
        <div style={{ height: "100%", width: "100%", overflow: 'hidden' }}>
            {showMap &&
                <div style={{ height: "100%", width: "100%", overflow: 'hidden' }}>
                    <MapContainer
                        center={[mapPositions[0][0], mapPositions[0][1]]} // Coordinates for the initial map center
                        zoom={9}                // Initial zoom level
                        zoomControl={zoomControl}
                        style={{ height: "100%", width: "100%" }} // Set the height and width of the map
                    >
                        <TileLayer
                            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />

                        {/* Render markers dynamically based on mapPositions */}



                        {mapPositions && mapPositions.map((position, index) => (

                          (true) &&
                            <Marker
                                key={index}
                                position={position}
                                icon={position[2]==='merchant' ? customIconMerchant : customIconWarehouse}   // Use the custom icon
                            >
                                {/*<Popup>
                                    [{position[0]}, {position[1]}]
                                </Popup>*/}
                            </Marker>

                        ))}
                    </MapContainer>
                </div>
            }
        </div>
    );
};

export default Map;
