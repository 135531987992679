import React from 'react';
import defaultImage from "../img/doc.png";

const StatsItem = ({ imageUrl, title, value, color }) => {

  const formatFormula = (formula) => {
    return formula.split(/(\d+)/).map((part, index) =>
      /\d/.test(part) ? <sub key={index}>{part}</sub> : part
    );
  };


  if(!imageUrl){
     imageUrl = defaultImage;
  }


  return (
    <div
      className={"stats-item "+color}
    >
      <div className="stats-item-icon">
        <img src={imageUrl} alt={title} className="stats-item-image"/>
      </div>
      <div>
        <div className="stats-item-title">{formatFormula(title)}</div>
        <div className="stats-item-value">

          {value}

        </div>
      </div>


    </div>
  );
};

export default StatsItem;
