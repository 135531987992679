import React from 'react';
import defaultImage from "../img/doc.png";
import { useConfig } from '../ConfigContext';
import {smart_newlines} from '../tools/utils';
import { useTranslation } from 'react-i18next';

const BadgeItem = ({ imageUrl, claim, name, icon, proof }) => {

  if(!imageUrl){
     imageUrl = defaultImage;
  }

  const config = useConfig();
  const { t } = useTranslation();

  return (
    <div
      className="badge-item"
    >
      <div className="badge-item-icon">
        <img src={config.DOMAIN + icon} alt={claim} className="badge-item-image"/>
      </div>
      <div className="badge-item-content">
        <div dangerouslySetInnerHTML={{ __html: smart_newlines(name,35) }} className="badge-item-title"></div>
        <div className="badge-item-value">

        { proof &&
          <span>
            <span className="big-bullet-green"> &#x25cf; </span> <span className="badge-item-verified"> {t("verificato")} </span>
          </span>
        }
        { !proof &&
          <span>
            <span className="big-bullet-blue"> &#x25cf; </span> <span className="badge-item-verified"> {t("dichiarato")}</span>
          </span>
        }

        </div>
      </div>


    </div>
  );
};

export default BadgeItem;
