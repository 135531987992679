import React, { useEffect, useState } from "react";

const PolicyPopup = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if popup has already been shown by looking into localStorage
    const hasSeenPopup = localStorage.getItem("hasSeenPopup");

    if (!hasSeenPopup) {
      // If no record of popup being shown, display it
      setIsVisible(true);
    }

    // Function to hide the popup and store the fact that it was shown
    const handleHidePopup = () => {
      setIsVisible(false);
      localStorage.setItem("hasSeenPopup", "true");
    };

    if (isVisible) {
      // Add event listeners for click and scroll if the popup is visible
      document.addEventListener("click", handleHidePopup);
      window.addEventListener("scroll", handleHidePopup);
    }

    // Cleanup event listeners on component unmount or when popup is hidden
    return () => {
      document.removeEventListener("click", handleHidePopup);
      window.removeEventListener("scroll", handleHidePopup);
    };
  }, [isVisible]);

  return (
    <div className={`policy-popup ${isVisible ? "visible" : "hidden"}`}>
      {text}
    </div>
  );
};

export default PolicyPopup;
