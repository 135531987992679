import React from 'react';
import mobileFrame from '../img/mobileframe.webp';
const PageContainer = ({ children }) => {


  const isDesktop = window.innerWidth > 768;

  return (
    <div>
      { isDesktop &&
          <div>
            <div className="mobileframe">
                          <img src={mobileFrame} alt="mobile frame" />

            </div>
            <main className="page-container page-container-desktop">
              {children}
            </main>
          </div>
      }
      { !isDesktop &&
            <main className="page-container">
              {children}
            </main>
      }
    </div>

  );
};

export default PageContainer;
