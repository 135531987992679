import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchIcon from '../img/search.png';

const MultibatchSelect = ({ multibatchData, batchIcon}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();

  // Function to filter the data based on the search query
  const filteredData = multibatchData.filter((item) =>
    item.foodchain_batch.internal_number
      .toLowerCase()
      .startsWith(searchQuery.toLowerCase())
  );

  return (
    <div className="multibatch-select">
      {/* Search Bar */}
      <div className="search-bar-wrapper" style={{ position: 'relative', marginBottom: '20px' }}>
        <input
          type="text"
          placeholder={t("search_multibatch_placeholder")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
          style={{
            width: '95%',
            padding: '10px',
            marginBottom: '20px',
            fontSize: '18px',
            border: '1px solid #ddd',
            borderRadius: '8px',
          }}
        />
        <span
          className="search-icon"
          style={{
            position: 'absolute',
            right: '10px',
            top: '35%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none', // Make it unclickable
          }}
        >
        <img src={searchIcon} alt="search" style={{
          height:"1.5em"
        }}/>
        </span>
      </div>
      {/* List of Batches */}
      {filteredData.map((item, index) => (
        <Link
          to={`/${item.foodchain_url.substring(2)}`}
          reloadDocument
          key={index}
        >
          <div className="horizontal-card">
            <img
              src={batchIcon}
              alt="batch"
              className="horizontal-card-image"
            />
            <div
              className="horizontal-card-info"
              style={{ marginLeft: '20px' }}
            >
              <div className="horizontal-card-prename">{t('lotto')}</div>
              <div className="horizontal-card-name">
                {item.foodchain_batch.internal_number}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default MultibatchSelect;
