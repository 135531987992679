import React, { useEffect, useState, useMemo } from 'react';
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import polyline from 'polyline';
import { useConfig } from '../ConfigContext';

import customMarkerImg1 from '../img/pin_2_white.png'; // Update the path to your image file
import customMarkerImg2 from '../img/pin_3_white.png'; // Update the path to your image file
import L from 'leaflet';



function calculateCenter(coords) {
    // Initialize sums for latitude and longitude
    let latSum = 0;
    let lonSum = 0;

    // Iterate over each coordinate, adding latitudes and longitudes
    coords.forEach(([lat, lon]) => {
        latSum += lat;
        lonSum += lon;
    });

    // Divide the sums by the number of coordinates to get the average
    const numCoords = coords.length;
    const centerLat = latSum / numCoords;
    const centerLon = lonSum / numCoords;

    return [centerLat, centerLon];
}




const MapRoute = ({points, selected_index}) => {
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const config = useConfig();
  /*var apiKey = config.GOOGLE_API_KEY_ROUTES;*/

  var the_domain = config.DOMAIN;
  var center = [45.712776, 7.005974];
  var zoom=6;

  console.log(points);

  if(points && points.length>0){
    // Create bounds from your coordinates
    // Calculate the center point of the bounds


    center = calculateCenter(points);

    // Calculate the bounding box
    let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

    points.forEach(([lat, lng]) => {
        minLat = Math.min(minLat, lat);
        maxLat = Math.max(maxLat, lat);
        minLng = Math.min(minLng, lng);
        maxLng = Math.max(maxLng, lng);
    });

    // Calculate the latitude and longitude "spread"
    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    // Approximate zoom level formula (based on max spread)
    function getZoomLevel(latDiff, lngDiff) {
        const maxDiff = Math.max(latDiff, lngDiff);
        console.log(maxDiff)
        if(maxDiff===0) return 8;
        return 30/(maxDiff*1.5 + 3) + 1.5;
    }
    zoom = getZoomLevel(latDiff, lngDiff);
    console.log(zoom);
  }


  const formatted_points = useMemo(() => {
    let fp = [];
    points.forEach(function(p){
      fp.push(p[0]+","+p[1])
    });
    return fp;
  }, [points]); // Only recalculate when 'points' changes




  // Create a custom icon
  const customIcon1 = L.icon({
      iconUrl: customMarkerImg1,   // Path to your custom marker image
      iconSize: [48, 64],         // Size of the icon
      iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
  });



  // Create a custom icon
  const customIcon2 = L.icon({
      iconUrl: customMarkerImg2,   // Path to your custom marker image
      iconSize: [48, 64],         // Size of the icon
      iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
      forceZIndex: 10000
  });




  useEffect(() => {

    if(!points) return;
    const getComplexRoute = async () => {


      const startPoint = formatted_points[0]; // First point is the start
      const endPoint = formatted_points[formatted_points.length - 1]; // Last point is the end
      const waypoints = formatted_points.slice(1, -1).join('|'); // Intermediate waypoints


      try {
        const response = await axios.get(
          the_domain + `mobile/direction/`,
          {
            params: {
              origin: startPoint,
              destination: endPoint,
              waypoints: waypoints,  // Add waypoints to the request
              /*key: apiKey*/
            }
          }
        );

        // Get the encoded polyline from the response
        const encodedPolyline = response.data.routes[0].overview_polyline.points;

        // Decode the polyline into an array of [lat, lng] points
        const decodedCoordinates = polyline.decode(encodedPolyline);

        // Set the decoded coordinates as the route in state
        setRouteCoordinates(decodedCoordinates);
      } catch (error) {
        console.error('Error fetching complex route data:', error);
      }
    };

    getComplexRoute();
  }, [points, formatted_points, the_domain]);

  return (
    <MapContainer center={ center ? center : [45.712776, 7.005974]} zoom={zoom?zoom:5} style={{ height: '300px', width: '100%' }}>
      <TileLayer
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />


      {points && points.lenfth>0 && points.map((position, index) => (
          <Marker
              key={index}
              position={position}
              icon={index===selected_index ? customIcon2 : customIcon1}   // Use the custom icon
              zIndexOffset={index===selected_index ? 4000 : 3000}
          >
              {/*<Popup>
                  [{position[0]}, {position[1]}]
              </Popup>*/}
          </Marker>
      ))}


      {/* Render the polyline if routeCoordinates is available */}
      {routeCoordinates.length > 0 && (
        <Polyline positions={routeCoordinates} color="#48A7AF" />
      )}
    </MapContainer>
  );
};

export default MapRoute;
