import React from 'react';
import { useConfig } from '../ConfigContext';

const Loader = ({metadata}) => {


  const config = useConfig();

  if(!metadata || !metadata.platform_logo){
      return (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      );
  }


  return (
    <div className="loader-container">
      <div className="loader-platform">
        <img className="platform-logo" src={config.DOMAIN + metadata.platform_logo} alt="platform logo"/>
      </div>
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
