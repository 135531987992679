import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'


import dettaglioIconInactive from '../img/dettaglio.png'; // Update the path to your image file
import aziendaIconInactive   from '../img/azienda.png'; // Update the path to your image file
import processiIconInactive  from '../img/processi.png'; // Update the path to your image file
import filieraIconInactive   from '../img/filiera.png'; // Update the path to your image file

import dettaglioIconActive from '../img/dettaglio_active.png'; // Update the path to your image file
import aziendaIconActive   from '../img/azienda_active.png'; // Update the path to your image file
import processiIconActive  from '../img/processi_active.png'; // Update the path to your image file
import filieraIconActive   from '../img/filiera_active.png'; // Update the path to your image file

const FooterNavigationItem = ({qr_code_hash, page, selected}) => {

  const { t } = useTranslation();

  const dettaglioPath = "/"+qr_code_hash+"/";
  const merchantPath = "/"+qr_code_hash+"/merchant";
  const processesPath = "/"+qr_code_hash+"/processes";
  const supplychainPath = "/"+qr_code_hash+"/supplychain";

  var the_icon = null;
  var the_path = null;

  if(page === 'dettaglio'){
    the_icon = selected ? dettaglioIconActive : dettaglioIconInactive;
    the_path = dettaglioPath;
  }
  if(page === 'azienda'){
    the_icon = selected ? aziendaIconActive : aziendaIconInactive;
    the_path = merchantPath;
  }
  if(page === 'processi'){
    the_icon = selected ? processiIconActive : processiIconInactive;
    the_path = processesPath;
  }
  if(page === 'filiera'){
    the_icon = selected ? filieraIconActive : filieraIconInactive;
    the_path = supplychainPath;
  }

  var the_class_footer_navigator_option = "footer-navigator-option" + (selected ? ' selected' : '');
  var the_class_footer_navigator_option_icon = "footer-navigator-option-icon" + (selected ? ' selected' : '');
  var the_class_footer_navigator_option_label = "footer-navigator-option-label" + (selected ? ' selected' : '');

  return (


        <Link to={the_path} >
          <div className={the_class_footer_navigator_option}>
            <div className={the_class_footer_navigator_option_icon}>
              <img src={the_icon} alt={t(page)} />
            </div>
            <div className={the_class_footer_navigator_option_label}>
              {t(page)}
            </div>
          </div>
        </Link>

  );
};

export default FooterNavigationItem;


