import React from 'react';
import { useTranslation } from 'react-i18next';

const Video = ({ data }) => {

  const { t } = useTranslation();

  const formatVideoUrl = (url) => {
      if(url.includes("youtube")){
        url = url.split('&')[0];
        url = url.replace("watch?v=", "embed/")+"?modestbranding=1&showsearch=0";
        return url;
      }

      if(url.includes("youtu.be")){
        url = url.split('&')[0];
        url = url.split('?')[0];
        url = url.replace("youtu.be/", "youtube.com/embed/")+"?modestbranding=1&showsearch=0";
        return url;
      }

      if(url.includes("youku.com")){
        const idMatch = url.match(/id_([A-Za-z0-9=]+)/);
        if (idMatch && idMatch[1]) {
          const videoId = idMatch[1];
          return `https://player.youku.com/embed/${videoId}`;
        }
      }

      if(url.includes("v.qq.com")){
        const match = url.match(/\/x\/cover\/(\w+)/);
        if(match){
          const videoId = match[1];
          const embedUrl = `https://v.qq.com/iframe/player.html?vid=${videoId}&tiny=0&auto=0`;
          return embedUrl;
        }
      }

      if(url.includes("bilibili.com")){
        url = url.split('&')[0];
        url = url.split('?')[0];
        const match = url.match(/\/video\/(BV\w+)/);
        if(match){
          const videoId = match[1];
          const embedUrl = `https://player.bilibili.com/player.html?bvid=${videoId}`;
          return embedUrl;
        }
      }


      return url;
  }


  if(data===null || typeof data === 'undefined' || Object.keys(data).filter((key)=>key==='video').length === 0){
     return <div></div>;
  }

  var the_video_url = data["video"];
  the_video_url = the_video_url.split('&')[0];


  the_video_url = formatVideoUrl(the_video_url);


  return (
    <div>
        <h2>{t('video')}</h2>
         <div className="video_container">
           <iframe className="video_embed"
          title="Video"
          src={the_video_url}>
          controls=0
          showinfo=0
          modestbranding=1
          </iframe>
        </div>
    </div>
  );
};

export default Video;
