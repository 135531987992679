import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import MapHeader from '../components/MapHeader';
import FooterNavigator from '../components/FooterNavigator';
import ExpandableText from '../components/ExpandableText';
import ContactCard from '../components/ContactCard';
import ExtraData from '../components/ExtraData';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

import Documents from '../components/Documents';
import Certifications from '../components/Certifications';
import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';


import { useTranslation } from 'react-i18next';
import { useConfig } from '../ConfigContext';

import imagePhone from '../img/tel.png';
import imageAddress from '../img/pin.png';
import imageWebsite from '../img/web.png';
import imageMail from '../img/mail.png';

import warehouseImage from '../img/magazzino.png';
import defaultMerchantImage from '../img/default_images/foodchain_merchant.png';


const MerchantPage = ({qr_code_hash, data}) => {


  const { t } = useTranslation();
  const config = useConfig();


  const metadata = useMetadata();


  var the_merchant = null;
  var the_warehouses = null;
  var the_fields = null;

  var merchantImages = [defaultMerchantImage];
  var merchantName = '';
  var merchantDescription = '';
  var merchantLongitude = null;
  var merchantLatitude = null;
  var mapPositions = [];
  var merchantPhone   = null;
  var merchantMail    = null;
  var merchantWebsite = null;
  var merchantAddress = null;
  var merchantHasPosition = false;
  var extra_data = null;
  var document_list = null;
  var certification_list = null;




  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);


  if(data && data.merchant && data.merchant.foodchain_merchant){
    the_merchant = data.merchant.foodchain_merchant;
  }

  if(data && data.warehouse && data.warehouse.length>0){
    the_warehouses = data.warehouse.map((m)=>m.foodchain_warehouse).filter((w)=> w.name && w.location && w.location.formatted);
    the_warehouses.forEach((m)=>{
      if(m.location && m.location.latitude && m.location.longitude){
         merchantHasPosition = true;
         mapPositions.push([m.location.latitude,m.location.longitude,'warehouse']);
      }
    });
  }

  if(data && data.warehouse && data.fields.length>0){
    the_fields = data.fields.map((m)=>m.foodchain_field).filter((w)=> w.name && w.location && w.location.formatted);
    the_fields.forEach((m)=>{
      if(m.location && m.location.latitude && m.location.longitude){
         merchantHasPosition = true;
         mapPositions.push([m.location.latitude,m.location.longitude,'field']);
      }
    });
  }

  if(the_merchant && the_merchant.images && the_merchant.images.length>0){
        merchantImages = the_merchant.images.map(image => config.DOMAIN + (image.image_cropped));
  }
  if(the_merchant){
        merchantName = the_merchant.name;
        merchantDescription = the_merchant.description;
        merchantPhone = the_merchant.phone_number;
        merchantMail = the_merchant.email;
        merchantWebsite = the_merchant.website;
        extra_data = the_merchant.extra;

        document_list = the_merchant.files.filter((d)=>!d.is_self_certificate);

        certification_list = the_merchant.files.filter((d)=>d.is_self_certificate);

        if(data && data.certification){
          var other_certs = data.certification.map((c) => c.foodchain_certification).filter((c)=>c.related_object_type==='merchant');
          certification_list = certification_list.concat(other_certs);
        }


  }
  if(the_merchant && the_merchant.address && the_merchant.address){
        merchantLongitude = the_merchant.address.longitude;
        merchantLatitude = the_merchant.address.latitude;
        mapPositions.push([merchantLatitude,merchantLongitude,'merchant']);
        merchantAddress = the_merchant.address.formatted;
        merchantHasPosition = true;
  }



  if(!data){
    return(
      <PageContainer>
        <div className="page-body">
          <Loader metadata={metadata} />
          <FooterNavigator qr_code_hash={qr_code_hash} selected_page={3} />
        </div>
      </PageContainer>
    )
  }



  return (
    <PageContainer>
      { merchantHasPosition && <MapHeader mapPositions={mapPositions} merchantIcon={merchantImages[0]} /> }

      <div className="page-body" style={{marginTop:"min(440px,100vw)"}}>

          <div className="merchant-basicinfo">
            <div>
              <img className="merchant-basicinfo-merchantimage" src={merchantImages[0]} alt="merchant"/>
            </div>
            <div className="product-basicinfo-texts">
              <h1 className="product-basicinfo-text-h1">{merchantName}</h1>
            </div>
          </div>

          <ExpandableText title={t('descrizione')} text={merchantDescription} />


            {the_warehouses && the_warehouses.length>0 &&
              <h2>{t('magazzini')}</h2>
            }

            {the_warehouses && the_warehouses.length>0 &&
              the_warehouses.map((item, index) => (

              <Link to={config.URL_DOMAIN+qr_code_hash+"/warehouse/"+item.item_id}>
              <div className="horizontal-card" key={index}>
                <div className="horizontal-card-image-outer">
                  <img src={warehouseImage} alt="warehouse" className="horizontal-card-image" />
                </div>
                <div className="horizontal-card-info">
                  <div className="horizontal-card-name">{item.name}</div>
                  <div className="horizontal-card-address">{item.location ? item.location.formatted : ''}</div>
                </div>
              </div>
              </Link>

            ))}



            {the_fields && the_fields.length>0 &&
              <h2>{t('campi')}</h2>
            }

            {the_fields && the_fields.length>0 &&
              the_fields.map((item, index) => (
              <Link to={config.URL_DOMAIN+qr_code_hash+"/field/"+item.item_id}>
              <div className="horizontal-card" key={index}>
                <div className="horizontal-card-image-outer">
                  <img src={warehouseImage} alt="warehouse" className="horizontal-card-image" />
                </div>
                <div className="horizontal-card-info">
                  <div className="horizontal-card-name">{item.name}</div>
                  <div className="horizontal-card-address">{item.location ? item.location.formatted : ''}</div>
                </div>
              </div>
              </Link>

            ))}


          { certification_list && certification_list.length>0 &&
             <div>
               <Certifications qr_code_hash={qr_code_hash} data={certification_list} />
             </div>

          }


          <h2>{t('contatti')}</h2>
          {merchantPhone   && <ContactCard type={'phone'} imageUrl={imagePhone}  name={t('telefono')} value={merchantPhone} />}

          {merchantMail    && <ContactCard type={'mail'} imageUrl={imageMail}   name={t('mail')} value={merchantMail} />}

          {merchantWebsite && <ContactCard type={'website'} imageUrl={imageWebsite}   name={t('sito_web')} value={merchantWebsite} />}

          {merchantAddress && <ContactCard type={'address'} imageUrl={imageAddress}  name={t('indirizzo')} value={merchantAddress} />}



          <div>
              <Documents documents={document_list}/>
          </div>


       <ExtraData data={extra_data} />


       </div>
       <FooterNavigator qr_code_hash={qr_code_hash} selected_page={2}/>
    </PageContainer>
  );
};

export default MerchantPage;
