// DateRangeDisplay.js
import React from 'react';
import {format_date} from "../tools/utils.js";
import { useTranslation } from 'react-i18next';
const ProcessDates = ({ date_start, date_end }) => {

  const { t } = useTranslation();



  return (
    <div className="date-range-container">
      { date_start &&
        <div className="date-box">
          <div className="date-box-title">{t('data_inizio')}</div>
          <div className="date-box-value">{format_date(date_start)}</div>
        </div>
      }
      { date_end &&
        <div className="date-box">
          <div className="date-box-title">{t('data_fine')}</div>
          <div className="date-box-value">{format_date(date_end)}</div>
        </div>
      }
    </div>
  );
};

export default ProcessDates;
