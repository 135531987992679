import axios from 'axios';

export default class FoodchainService {
    // Initializing important variables
    constructor(DOMAIN, IPFS_DOMAIN) {


        this.domain = DOMAIN;
        this.ipfsDomain = IPFS_DOMAIN;
        this.ipfsPath = "/media/ipfs/api/v0";
        this.getLot = this.getLot.bind(this);
        this.getIPFS = this.getIPFS.bind(this);
        //NULL HASH = codifica ipfs dell'hash 0x0000000000000000000000000000000000000000000000000000000000000000
        this.NULL_HASH = "QmNLei78zWmzUdbeRB3CiUfAizWUrbeeZh5K1rhAQKCh51";
    }

    getLot(lotId) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };

        return axios.post(`${this.domain}/mobile/query/`,
            { qrcode_uuid: lotId },
            { headers: headers }
        )
    }

    getIPFS(ipfsHash) {
        if(ipfsHash === this.NULL_HASH) return null;
        return axios.get(`${this.ipfsDomain}${ipfsHash}`)
    }

    getIPFSWithTimeout(ipfsHash, timeout = 3000) {
      if (ipfsHash === this.NULL_HASH) return null;

      // Race between the axios request and a timeout
      return Promise.race([
        axios.get(`${this.ipfsDomain}${ipfsHash}`), // Axios request
        new Promise(resolve => setTimeout(() => resolve(null), timeout)) // Timeout set to 3 seconds
      ]);
    }


}
