import React from 'react';

const TitleHeader = ({ title }) => {
  return (
    <header className="title-header">
      <h1>{title}</h1>
    </header>
  );
};


export default TitleHeader;
