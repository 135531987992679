import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
//import LanguageDetector from 'i18next-browser-languagedetector';
//import HttpBackend from 'i18next-http-backend';

// Translation files for each language
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  //.use(HttpBackend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'it', // Default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

export default i18n;
