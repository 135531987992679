import React, { createContext, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfig } from '../ConfigContext';

const MetadataContext = createContext();

export const MetadataProvider = ({ children, hashCode }) => {
  const [metadata, setMetadata] = useState(null);
  const config = useConfig();

  const placeholderMetadata = {
    "contract_address": "",
    "platform": "FoodChain QR",
    "platform_logo": "",
    "PAGE_TITLE": "FoodChain QR",
    "META_AUTHOR": "FoodChain QR",
    "BUSINESS_INFO": "FoodChain QR",
    "BUSINESS_COOKIES": "Kaboom Srl",
    "PRIMARY_COLOR": "#e53e30",
    "SECONDARY_COLOR": "#19171C",
    "TERTIARY_COLOR": "#69822f",
    "ANDROID_URL": "",
    "APPLE_URL": "",
    "META_DESCRIPTION": "FoodChain QR",
    "META_KEYWORDS": "FoodChain, QR",
    "OG_TITLE": "FoodChain QR",
    "OG_IMAGE": "",
    "OG_DESCRIPTION": "",
    "OG_URL": "",
    "TWITTER_CARD": "FoodChain QR",
    "TWITTER_TITLE": "FoodChain QR",
    "TWITTER_DESCRIPTION": "FoodChain QR",
    "TWITTER_IMAGE": "FoodChain QR"
  }



  async function fetchWithTimeout(url, timeout = 3000) {
    // Race between the fetch call and a timeout promise
    return Promise.race([
      fetch(url), // The fetch request
      new Promise(resolve => setTimeout(() => resolve(null), timeout)) // Timeout set to 3 seconds
    ]);
  }

  const fetchMetadata = async (hashCode) => {
    try {
      const baseUrl = config.METADATA_BASEURL;
      //const response = await fetch(`${baseUrl}${hashCode}`);
      const response = await fetchWithTimeout(`${baseUrl}${hashCode}`, 3000);
      if(response){
        const data = await response.json();
        setMetadata(data);
        console.log("METADATA");
        console.log(data);
      }
      else{
        console.log("COULDN'T RETRIEVE METADATA");
      }

    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  };

  useEffect(() => {
    if (hashCode) {
      fetchMetadata(hashCode);
    }
  },[]);

  const createMetaTags = (metadata) => {

    if(!metadata)
    {
      return [];
    }

    const metaTags = [];

    // Handle 'name' type meta tags
    const nameTags = {
      META_DESCRIPTION: 'description',
      META_KEYWORDS: 'keywords',
      META_AUTHOR: 'author'
    };

    Object.keys(nameTags).forEach(key => {
      if (metadata[key]) {
        metaTags.push(<meta key={key} name={nameTags[key]} content={metadata[key]} />);
      }
    });

    // Handle 'property' type meta tags for OpenGraph
    const ogTags = {
      OG_TITLE: 'og:title',
      OG_DESCRIPTION: 'og:description',
      OG_IMAGE: 'og:image',
      OG_URL: 'og:url'
    };

    Object.keys(ogTags).forEach(key => {
      if (metadata[key]) {
        metaTags.push(<meta key={key} property={ogTags[key]} content={metadata[key]} />);
      }
    });

    // Handle 'property' type meta tags for Twitter
    const twitterTags = {
      TWITTER_CARD: 'twitter:card',
      TWITTER_TITLE: 'twitter:title',
      TWITTER_DESCRIPTION: 'twitter:description',
      TWITTER_IMAGE: 'twitter:image'
    };

    Object.keys(twitterTags).forEach(key => {
      if (metadata[key]) {
        metaTags.push(<meta key={key} name={twitterTags[key]} content={metadata[key]} />);
      }
    });

    return metaTags;
  };




  if(!metadata){
    return (
      <MetadataContext.Provider value={placeholderMetadata}>
        {children}
      </MetadataContext.Provider>
    );
  }

  return (
    <MetadataContext.Provider value={metadata}>
      {metadata && (
        <Helmet>
          <title>{metadata.PAGE_TITLE}</title>
          {createMetaTags(metadata)}
        </Helmet>
      )}
      {children}
    </MetadataContext.Provider>
  );
};

export const useMetadata = () => {
  return useContext(MetadataContext);
};
